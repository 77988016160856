// .toolbar .dx-item .dx-button {
//     .dx-icon {
//         color: $clr-gray;
//     }

//     &:hover {
//         border-color: $clr-text;

//         .dx-icon {
//             color: $clr-text
//         }
//     }
// }

.dx-dropdownbutton-content .dx-list-item {
    display: block;
}

.toolbar {
    .dx-menu.dx-widget.dx-menu-base {
        border: 1px solid $clr-border;

        .dx-menu-item-has-submenu {
            border: none;
        }
    }

    .dx-toolbar-item-content {
        height: 100%;

        * {
            height: 100%;
        }
    }
}