::ng-deep {
    .dx-texteditor-input-container.dx-tag-container {
        padding: 0 !important;
        height: 34px !important;

        .dx-tag{
            align-self: flex-end;
            margin: 0;
            transform-origin: left bottom;
            scale: 0.95;
        }
    }

    .dx-tag-remove-button::after,
    .dx-tag-remove-button::before {
        background-color: white;
    }
}