$clr-accent: rgb(185, 4, 4);
$clr-text: rgb(0, 0, 0, 0.87);
$clr-bg: rgb(255, 255, 255);
$clr-border: rgb(221, 221, 221);
$clr-success: rgb(92, 184, 92);
$clr-warning: rgb(240, 173, 78);
$clr-danger: rgb(217, 83, 79);

$clr-primary: rgb(185, 4, 4);
$clr-secondary: rgb(51, 51, 51);
$clr-white: rgb(255, 255, 255);
$clr-black: rgba(0, 0, 0, 0.87);
$clr-gray: #a0a0a0;

$clr-tab-bg: #f7f7f7;

.bg-success {
    background-color: $clr-success;
}

.bg-danger {
    background-color: $clr-danger;
}
.bg-secondary {
    background-color: $clr-secondary;
}

.bg-gray {
    background-color: $clr-gray;
}

.text-gray {
    color: $clr-gray;
}

.bg-accent {
    background-color: $clr-accent;
}

.cl-accent {
    color: $clr-accent !important;
}
.cl-gray {
    color: $clr-gray !important;
}
.cl-success {
    color: $clr-success !important;
}
.cl-warning {
    color: $clr-warning !important;
}
.cl-danger {
    color: $clr-danger !important;
}