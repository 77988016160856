@import "variables";

@import "./src/lib/drawer/drawer.component.scss";
@import "./src/lib/modal/modal.component.scss";
@import "./src/lib/user-menu/user-menu.component.scss";
@import "./src/lib/flag/flag.component.scss";
@import "./src/lib/tabs/tabs.component.scss";
@import "./src/lib/toolbar/toolbar.component.scss";
@import "./src/lib/badge/badge.component.scss";
@import "./src/lib/progress-bar/progress-bar.component.scss";
@import "./src/lib/form/tagbox/tagbox.component.scss";
@import "./src/lib/file-manager/file-manager.component.scss";

.border-none {
    border: none !important;
}