// Task 3328: Modifier les mise en évidence des onglets
.dx-tabs.syslink-tabs .dx-tab-selected {
    background-color: $clr-accent;
    color: $clr-white;
}

// Task 3329: Modifier le style des entêtes de grilles
dx-data-grid.dx-widget .dx-datagrid table tbody tr.dx-header-row{
    background-color: $clr-gray;
    color: $clr-white;
    font-weight: bold;
}

// Task 3330: Mettre à jour l'apparence des champs
.dx-texteditor-label {
    margin-top: 0px !important;
}